import React from "react";
import WipeBtnBlack from "../../components/atoms/buttons/WipeBtnBlack";

import { useLanguage } from "../../languageContext";

const SolutionsHeader = ({
  headingOne,
  headingTwo,
  paragraph,
  btnText,
  page,
  mobileImage,
  mobileAltText,
}) => {
  const currentLanguage = useLanguage();

  return (
    <div className="section ">
      <div
        className={`solutions-desktop-wrapper bg-image-${page}-${
          currentLanguage ? currentLanguage : "en"
        }`}
      >
        <div className="padding-section"></div>
      </div>
      <div className=" solutions-desktop-wrapper container solutions-container">
        <div className="solutions-header-wrapper">
          <div className="solutions-wrapper-shadow">
            <div
              className={`header-wrapper hover-${
                currentLanguage ? currentLanguage : "en"
              }`}
            >
              <h1
                className="w-90-wrapper heading-bottom-space heading m-top-20px"
                style={{ marginTop: "40px" }}
              >
                {headingOne}
                <br></br>
                {headingTwo}
              </h1>
              <p
                className="w-90-wrapper  para common-bottom-space"
                dangerouslySetInnerHTML={{ __html: paragraph }}
              ></p>
              <div className="m-top-20px">
                <WipeBtnBlack
                  text="Talk To An Expert"
                  url="/contactus/"
                ></WipeBtnBlack>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="solutions-mobile-wrapper">
        <div className="container solutions-container">
          <div className="image-wrapper">
            {mobileImage && <img src={mobileImage} alt={mobileAltText} />}
          </div>
          <div className="solutions-header-wrapper">
            <div className="solutions-wrapper-shadow">
              <div
                className={`header-wrapper left-border-${
                  currentLanguage ? currentLanguage : "en"
                }`}
              >
                <h2 className="w-90-wrapper heading-bottom-space heading m-top-20px">
                  {headingOne}
                  <br></br>
                  {headingTwo}
                </h2>
                <p className="w-90-wrapper  para common-bottom-space">
                  {paragraph}
                </p>
                <div className="m-top-20px">
                  <WipeBtnBlack
                    text="Talk To An Expert"
                    url="/contactus/"
                  ></WipeBtnBlack>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolutionsHeader;
