import React, { useState } from "react";

import RightArren from "../../../images/solutionsassets/right-arrow-en.svg";
import RightArrin from "../../../images/solutionsassets/right-arrow-in.svg";
import RightArrja from "../../../images/solutionsassets/right-arrow-ja.svg";

import { useLanguage } from "../../../languageContext";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const SliderItem = ({
  heading,
  tagline,
  listItems,
  sliderImage,
  styleBullets,
  url,
  isTraining,
  isUrl,
  hideExplore,
}) => {
  // console.log(isUrl, url);

  const currentLanguage = useLanguage();
  const [showExplore, setShowExplore] = useState(false);
  return isUrl ? (
    <AnchorLink to={url ? url : "#"} replace>
      {/* border-${
            currentLanguage ? currentLanguage : "en"
          } */}
      <div
        className={`slider-item border-${
          currentLanguage ? currentLanguage : "en"
        }`}
      >
        <div className="image-wrapper">
          <img
            src={
              sliderImage
                ? sliderImage
                : "https://images.unsplash.com/photo-1593642532454-e138e28a63f4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=60"
            }
            alt={heading}
          ></img>
        </div>
        <div
          role="button"
          tabIndex={0}
          className={`slider-item-info `}
          onMouseEnter={() => setShowExplore(true)}
          onMouseLeave={() => setShowExplore(false)}
        >
          <h3 className="uppercase-text heading-margin card-text bold">
            {heading}
          </h3>
          <h4 className="tagline para heading-margin">{tagline}</h4>
          <ul className={`para ${styleBullets ? "" : "no-bullet-style"}`}>
            {listItems && listItems.map((list) => <li>{list}</li>)}
          </ul>
          <button
            className={`para ${
              showExplore
                ? `color-${currentLanguage ? currentLanguage : "en"} show`
                : `color-en`
            }`}
          >
            {hideExplore
              ? null
              : isTraining
              ? "Get In Touch"
              : `Explore - ${heading}`}

            {hideExplore ? null : (
              <img
                src={
                  currentLanguage === "en"
                    ? RightArren
                    : currentLanguage === "in"
                    ? RightArrin
                    : currentLanguage === "ja"
                    ? RightArrja
                    : RightArren
                }
                alt={heading}
              ></img>
            )}
          </button>
        </div>
      </div>
    </AnchorLink>
  ) : (
    <div
      className={`slider-item height-100 border-${
        currentLanguage ? currentLanguage : "en"
      }`}
    >
      <div className="image-wrapper">
        <img
          src={
            sliderImage
              ? sliderImage
              : "https://images.unsplash.com/photo-1593642532454-e138e28a63f4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=60"
          }
          alt={heading}
        ></img>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={`slider-item-info `}
        onMouseEnter={() => setShowExplore(true)}
        onMouseLeave={() => setShowExplore(false)}
      >
        <h3 className="uppercase-text heading-margin card-text bold">
          {heading}
        </h3>
        <h4 className="tagline para heading-margin">{tagline}</h4>
        <ul className={`para ${styleBullets ? "" : "no-bullet-style"}`}>
          {listItems && listItems.map((list) => <li>{list}</li>)}
        </ul>
        <button
          className={`para ${
            showExplore
              ? `color-${currentLanguage ? currentLanguage : "en"} show`
              : `color-en`
          }`}
        >
          {hideExplore
            ? null
            : isTraining
            ? "Get In Touch"
            : `Explore - ${heading}`}

          {hideExplore ? null : (
            <img
              src={
                currentLanguage === "en"
                  ? RightArren
                  : currentLanguage === "in"
                  ? RightArrin
                  : currentLanguage === "ja"
                  ? RightArrja
                  : RightArren
              }
              alt={heading}
            ></img>
          )}
        </button>
      </div>
    </div>
  );
};

export default SliderItem;
