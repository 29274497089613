import React, { useEffect, useRef } from "react";
import SliderItem from "../../components/atoms/SliderItem/SliderItem";

import { gsap, Power3 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import IconScrollen from "../../images/solutionsassets/iconScroll-en.svg";
import IconScrollin from "../../images/solutionsassets/iconScroll-in.svg";
import IconScrollja from "../../images/solutionsassets/iconScroll-ja.svg";

import { useLanguage } from "../../languageContext";
const SolutionsServices = ({
  SliderItems,
  headingOne,
  headingTwo,
  headingThree,
}) => {
  let RightScroll = useRef(null);
  const currentLanguage = useLanguage();
  const setTriggersGsap = () => {
    try {
      gsap.registerPlugin(ScrollTrigger);
      let scollWidth = 800;
      try {
        let serviceContainer = document.querySelector(
          ".services-padding-wrapper"
        );
        scollWidth = serviceContainer.clientWidth;
      } catch (error) {}
      console.log(scollWidth);
      let xvalue = parseInt(scollWidth - 350);
      const tl = gsap.timeline({
        // paused: true,
        scrollTrigger: {
          trigger: RightScroll,
          scrub: true,
          start: "-100px",
          end: "+=1000",
          pin: true,
        },
      });

      tl.to(RightScroll, {
        x: "-=800",
        duration: 2,
        ease: Power3.easeOut,
      });
    } catch (error) {}
  };
  useEffect(() => {
    setTriggersGsap();
    return () => {
      let triggers = ScrollTrigger.getAll();
      triggers.forEach((trigger) => {
        trigger.kill();
      });
    };
  }, []);

  return (
    <div className="section m-top-10 services-padding-wrapper">
      <div className="container">
        <div className="x-scroll">
          <div
            className="solutions-services-wrapper"
            ref={(el) => (RightScroll = el)}
          >
            <div className="heading-wrapper">
              <div className="heading">
                {headingOne}
                <br></br> {headingTwo}
                <br></br> {headingThree}
              </div>
              <div className={`para color-${currentLanguage}`}>
                Page will scroll this way{" "}
                {currentLanguage === "en" ? (
                  <img src={IconScrollen} alt="Icon Scroll"></img>
                ) : currentLanguage === "in" ? (
                  <img src={IconScrollin} alt="Icon Scroll"></img>
                ) : currentLanguage === "ja" ? (
                  <img src={IconScrollja} alt="Icon Scroll"></img>
                ) : (
                  <img src={IconScrollen} alt="Icon Scroll"></img>
                )}
              </div>
            </div>
            {SliderItems &&
              SliderItems.map(
                ({ slideHeading, paragraph, listItems, url, slideImage }) => (
                  <>
                    <SliderItem
                      heading={slideHeading}
                      tagline={paragraph}
                      listItems={listItems}
                      url={`/${url}`}
                      isUrl={true}
                      sliderImage={slideImage.url}
                      styleBullets={true}
                    ></SliderItem>
                  </>
                )
              )}
          </div>
        </div>
      </div>
      <div className="mobile-container">
        <div className="solution-slider-wrapper-mobile">
          <div className="heading-wrapper">
            <div className="heading">
              {headingOne}
              <br></br> {headingTwo}
              <br></br> {headingThree}
            </div>
          </div>
          {SliderItems &&
            SliderItems.map(
              ({ slideImage, slideHeading, paragraph, listItems, url }) => (
                <SliderItem
                  heading={slideHeading}
                  tagline={paragraph}
                  listItems={listItems}
                  sliderImage={slideImage.url}
                  url={`/${url}`}
                  isUrl={true}
                  styleBullets={true}
                ></SliderItem>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default SolutionsServices;
